import { OrderSubStatus } from '~/lib/services/store/order/order.dto'

interface ObjectStatusInterface {
  text: string
  style: string
}

export const useOrderStatus = () => {
  const { t } = useLocale()
  const typesDescriptions: { [key in OrderSubStatus]: ObjectStatusInterface } = {
    VALIDATION_PENDING: { text: t('orders.status.pending'), style: 'text-mcd-red' },
    PAYMENT_PENDING: { text: t('orders.status.pending'), style: 'text-mcd-red' },
    PAYMENT_PENDING_COUNTER: { text: t('orders.status.pendingCounter'), style: 'text-mcd-red' },
    PAYMENT_DONE: { text: t('orders.status.paymentDone'), style: 'text-mcd-green' },
    PAYMENT_DONE_MCDIA: { text: t('orders.status.paymentDoneMcdia'), style: 'text-mcd-green' },
    PAYMENT_DONE_MCDIA_3DAYS: {
      text: t('orders.status.paymentDoneMcdia3days'),
      style: 'text-mcd-green',
    },
    PAYMENT_DONE_MCDIA_INDATE: {
      text: t('orders.status.paymentDoneMcdiaInDate'),
      style: 'text-mcd-darkBlue',
    },
    PAYMENT_DONE_MCDIA_EXPIRED: {
      text: t('orders.status.paymentDoneMcdiaExpired'),
      style: 'text-mcd-secondaryGrey',
    },
    FLEX_SENDING: { text: t('orders.status.goRestaurant'), style: 'text-mcd-darkBlue' },
    FLEX_RECEIVED: { text: t('orders.status.received'), style: 'text-mcd-green' },
    FLEX_ACCEPTED: { text: t('orders.status.accepted'), style: 'text-mcd-green' },
    FLEX_PREPARING: { text: t('orders.status.inProgress'), style: 'text-mcd-green' },
    FLEX_READY: { text: t('orders.status.ready'), style: 'text-mcd-brown' },
    FLEX_DELIVERING: { text: t('orders.status.delivering'), style: 'text-mcd-secondaryGrey' },
    CANCELLED: { text: t('orders.status.cancelled'), style: 'text-mcd-secondaryGrey' },
    FLEX_CANCELLED: { text: t('orders.status.cancelled'), style: 'text-mcd-secondaryGrey' },
    FLEX_ERROR: { text: t('orders.status.error'), style: 'text-mcd-secondaryGrey' },
    FLEX_STAGING: { text: t('orders.status.delivered'), style: 'text-mcd-green' },
    FLEX_DELIVERED: { text: t('orders.status.delivered'), style: 'text-mcd-green' },
    FINISHED: { text: t('orders.status.finished'), style: 'text-mcd-secondaryGrey' },
    PICKUP_EXPIRED: { text: t('orders.status.pickupExpired'), style: 'text-mcd-secondaryGrey' },
  }

  const typeIcon = {
    DLV: { icon: '/images/icons/restaurants/delivery.svg', text: t('common.DLV') },
    MOP: { icon: '/images/icons/restaurants/pickUp.svg', text: t('common.MOP') },
  }

  const selectIcon = (typeDelivery: string) =>
    typeDelivery === 'DLV' ? typeIcon.DLV : typeIcon.MOP

  const getStatus = (status: OrderSubStatus) => typesDescriptions[status]

  return { getStatus, selectIcon }
}
